<template>
  <div class="merchantlist">
    <NavBar :list="navList" name="商铺列表"></NavBar>
    <div class="public-box">
      <el-form :inline="true">
        <el-form-item>
          <MyInput v-model="formInline.merchants_stall_number" :disabled="formInline.property_end_time"
            placeholder="输入商铺号">
            <template slot="pre">商铺号:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyInput v-model="formInline.merchants_stall_name" :disabled="formInline.property_end_time"
            placeholder="输入商户姓名">
            <template slot="pre">商户姓名:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <el-cascader @change="changeBuilding" :props="props" clearable placeholder="请选择楼栋楼层"></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-select v-model="formInline.property_type_id" clearable :disabled="formInline.property_end_time"
            placeholder="输入商铺类型">
            <el-option v-for="item in typeList" :key="item.property_type_id" :label="item.name"
              :value="item.property_type_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker :disabled="formInline.property_end_time" v-model="date" @change="changeDate" type="daterange"
            format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="租赁开始日期"
            end-placeholder="租赁结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="formInline.property_end_time" @change="feizlchange" format="yyyy-MM-dd"
            value-format="yyyy-MM-dd" type="date" placeholder="非租赁日期"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-col :span="11">
            <MyInput :disabled="formInline.property_end_time" v-model="formInline.merchants_stall_size_min"
              placeholder="输入商铺面积">
              <template slot="pre">商铺面积起:</template>
            </MyInput>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <MyInput :disabled="formInline.property_end_time" v-model="formInline.merchants_stall_size_max"
              placeholder="输入商铺面积">
              <template slot="pre">商铺面积止:</template>
            </MyInput>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-select v-model="formInline.status" clearable placeholder="租赁状态">
            <el-option :value="1" label="已出租"></el-option>
            <el-option :value="2" label="未出租"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <MyButton icon title="搜索" @click="currentChange(1)">
            <template slot="preImage">
              <img src="../../../assets/img/icon/search.png" />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton title="导出" :accessId="57610" @click="exported">
            <template slot="preImage">
              <img src="../../../unit/img/down.png" />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
      <div class="public-table">
        <el-table :data="tableData" height="85%" v-loading="loading"
          :header-cell-style="{ 'text-align': 'center', background: 'rgb(245, 245, 245)' }"
          :cell-style="{ 'text-align': 'center' }">
          <el-table-column label="商铺号" prop="merchants_stall_number"></el-table-column>
          <el-table-column label="楼栋" prop="building_name"></el-table-column>
          <el-table-column label="楼层" prop="building_floor_name"></el-table-column>
          <el-table-column label="商铺类型" prop="property_merchants_stall_type"></el-table-column>
          <el-table-column label="商铺面积" prop="merchants_stall_size">
            <template slot-scope="scope">
              <span>{{ scope.row.merchants_stall_size }}㎡</span>
            </template>
          </el-table-column>
          <el-table-column label="商户姓名" prop="merchants_stall_name">
            <template slot-scope="{ row }">
              <div>{{ row.merchants_stall_name || "/" }}</div>
            </template>
          </el-table-column>
          <el-table-column label="指导价（元）" prop="merchants_stall_money"></el-table-column>
          <el-table-column label="实租价（元）" prop="merchants_stall_price"></el-table-column>
          <el-table-column label="租赁时间段">
            <template slot-scope="scope">
              <span>{{ scope.row.merchants_stall_start_time }}</span>
              <span v-if="scope.row.merchants_stall_end_time">~ {{ scope.row.merchants_stall_end_time }}</span>
            </template>
          </el-table-column>
          <el-table-column label="租赁状态">
            <template slot-scope="{ row }">
              <span>{{ row.contract_id > 0 ? "已出租" : "未出租" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div class="slot-box">
                <pop tips="历史出租信息" :accessId="40327" @myclick="history(scope.row)" style="marginRight: 10px;">
                  <img class="icon" src="../../../assets/img/icon/lishi.png" alt />
                </pop>
                <pop tips="绑定智能水表" :accessId="40327" @myclick="waterMeter(scope.row)" style="marginRight: 10px;">
                  <img class="icon" src="../../../assets/img/icon/waterMeter.png" alt />
                </pop>
                <pop tips="绑定智能电表" :accessId="40327" @myclick="electricityMeter(scope.row)">
                  <img class="icon" src="../../../assets/img/icon/electricityMeter.png" alt />
                </pop>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="public-page">
        <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total"
          :page-size="formInline.pageSize" :current-page="formInline.pageNum"
          @current-change="currentChange"></el-pagination>
      </div>
    </div>
    <el-dialog title="历史出租数据" :visible.sync="dialogVisible" width="880px" @close="handleClose"
      :close-on-click-modal="false">
      <el-form :inline="true">
        <el-form-item label="商铺号:">
          {{ historyMerchantsStallNumber }}
        </el-form-item>
      </el-form>

      <el-table :data="historyTableData" max-height="500px" :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column label="商户姓名" prop="merchants_stall_name"></el-table-column>
        <el-table-column label="指导价（元）" prop="property_price"></el-table-column>
        <el-table-column label="实租价（元）" prop="merchants_stall_price"></el-table-column>
        <el-table-column label="租赁时间段" prop="merchants_stall_name">
          <template slot-scope="scope">
            <span>{{ scope.row.merchants_stall_start_time }}</span>
            <span v-if="scope.row.merchants_stall_end_time">~ {{ scope.row.merchants_stall_end_time }}</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <MyButton title="关闭" @click="dialogVisible = false"></MyButton>
      </span>
    </el-dialog>
    <el-dialog :title="waterMeterTitle" :visible.sync="waterMeterVisible" width="500px" @close="closeWaterMeter">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="表号:" prop="meter_number">
          <el-input v-model="ruleForm.meter_number" placeholder="请输入表号"></el-input>
        </el-form-item>
        <el-form-item label="商铺号:">
          <el-input v-model="ruleForm.merchants_stall_name" disabled placeholder="商铺号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton title="关 闭" @click="waterMeterVisible = false" right></MyButton>
        <MyButton title="确 定" @click="confirm"></MyButton>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "merchantlist",
  data () {
    let that = this;
    return {
      navList: [
        {
          name: "商铺列表",
        },
      ],
      formInline: {
        pageNum: 1,
        pageSize: 10,
        property_type_id: "",
        merchants_stall_number: "",
        property_end_time: null,
        merchants_stall_size_min: "",
        merchants_stall_size_max: "",
        merchants_stall_name: "",
        beginTime: "",
        endTime: "",
        formInline: "",
        status: "",
        building_id: '',
        building_floor_id: ''
      },
      date: [],
      total: 0,
      tableData: [],
      typeList: [],
      historyTableData: [],
      historyMerchantsStallNumber: "",
      dialogVisible: false,
      loading: false,
      waterMeterVisible: false,
      ruleForm: {
        meter_number: '',
        merchants_stall_id: '',
        merchants_stall_name: '',
        meter_type: ''
      },
      rules: {
        meter_number: [{ required: true, message: '请输入表号', trigger: 'blur' }]
      },
      waterMeterTitle: '绑定智能水表',
      props: {
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          that.getLazyLoad(node, resolve);
        }
      }
    };
  },
  created () {
    //do something after creating vue instance
    this.getList();
    this.getType();
  },
  methods: {
    changeBuilding (e) {
      if (e.length == 1) {
        this.formInline.building_id = e[0];
        this.formInline.building_floor_id = '';
      } else if (e.length == 2) {
        this.formInline.building_id = e[0];
        this.formInline.building_floor_id = e[1];
      } else {
        this.formInline.building_id = '';
        this.formInline.building_floor_id = '';
      }
    },
    getLazyLoad (node, resolve) {
      const { level } = node;
      if (level == 0) {
        this.$request.HttpPost('/building/list', {
          pageNum: 1,
          pageSize: 99999
        }).then((res) => {
          const tempArr = [];
          if (res.data.list) {
            res.data.list.forEach((item) => {
              tempArr.push({
                ...item,
                label: item.name,
                value: item.building_id,
                leaf: level >= 2
              });
            });
          }
          resolve(tempArr);
        })
      } else {
        this.$request.HttpGet('/floor/list', {
          building_id: node.value
        }).then((res) => {
          const tempArr = [];
          if (res.data.list) {
            res.data.list.forEach((item) => {
              tempArr.push({
                ...item,
                label: item.name,
                value: item.building_floor_id,
                leaf: 2
              });
            });
          }
          resolve(tempArr);
        })
      }
    },
    /**
     * 打开绑定智能水表弹窗
     * @param {参数} item 
     */
    waterMeter (item) {
      this.ruleForm.merchants_stall_id = item.merchants_stall_id;
      this.ruleForm.merchants_stall_name = item.merchants_stall_number;
      this.ruleForm.meter_type = 1;
      this.waterMeterTitle = '绑定智能水表';
      this.waterMeterVisible = true;
    },
    /**
     * 打开绑定智能电表弹窗
     * @param {参数} item 
     */
    electricityMeter (item) {
      this.ruleForm.merchants_stall_id = item.merchants_stall_id;
      this.ruleForm.merchants_stall_name = item.merchants_stall_number;
      this.ruleForm.meter_type = 2;
      this.waterMeterTitle = '绑定智能电表';
      this.waterMeterVisible = true;
    },
    /**
     * 确认绑定智能水表和电表
     */
    confirm () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$request.dailyPost('/meterItem/add', this.ruleForm).then(() => {
            this.$common.notifySuccess('绑定成功');
            this.dialogVisible = false;
          });
        }
      });
    },
    closeWaterMeter () {
      this.ruleForm = {
        meter_number: '',
        merchants_stall_id: '',
        merchants_stall_name: '',
        meter_type: ''
      };
    },
    handleClose () {
      this.historyTableData = [];
      this.historyMerchantsStallNumber = "";
    },
    history (item) {
      this.historyMerchantsStallNumber = item.merchants_stall_number;
      this.$request.HttpGet("/contract/merchantsStallList", {
        merchants_stall_id: item.merchants_stall_id,
      }).then((res) => {
        this.historyTableData = res.data;
        this.dialogVisible = true;
      });
    },
    changeDate (event) {
      if (event) {
        this.formInline.beginTime = event[0];
        this.formInline.endTime = event[1];
      } else {
        this.formInline.beginTime = "";
        this.formInline.endTime = "";
      }
    },
    getType () {
      this.$request
        .HttpGet("/type/list", {
          pageNum: 1,
          pageSize: 10000,
        })
        .then((res) => {
          this.typeList = res.data.list;
        });
    },
    getList () {
      this.loading = true;
      this.$request
        .HttpGet("/contract/stallList", this.formInline)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    currentChange (page) {
      this.formInline.pageNum = page;
      this.getList();
    },
    exported () {
      this.$exported(
        "/contract/stallListExport",
        this.formInline,
        "商铺列表.xlsx"
      );
    },
    feizlchange (date) {
      if (date) {
        console.log(date);
        this.formInline.status = 2;
        this.formInline.merchants_stall_number = "";
        this.formInline.merchants_stall_name = "";
        this.formInline.property_type_id = "";
        this.formInline.merchants_stall_size_min = "";
        this.formInline.merchants_stall_size_max = "";
        this.date = "";
        this.formInline.beginTime = "";
        this.formInline.endTime = "";
      } else {
        console.log(date);
        if (this.formInline.status !== 1) {
          this.formInline.status = "";
        }
        // this.$forceUpdate()
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.line {
  text-align: center;
}
</style>
